import javaLogo from "../assets/img/java-logo.svg";
import cSharpLogo from "../assets/img/c-sharp-logo.svg";
import pythonLogo from "../assets/img/python-logo.svg";
import sqlLogo from "../assets/img/sql-logo.svg";
import springBootLogo from "../assets/img/spring-boot-logo.svg";
import dotNetLogo from "../assets/img/dot-net-logo.svg";
import awsLogo from "../assets/img/aws-logo.svg";
import azureLogo from "../assets/img/azure-logo.svg";
import gitLogo from "../assets/img/git-logo.svg";
import Carousel from 'react-multi-carousel';
import colorSharp from "../assets/img/color-sharp.png"
import 'react-multi-carousel/lib/styles.css';

export const Skills = () => {

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Skills</h2>
                        <p>
                        Here are a few of the technologies I have worked with in the past. However, this list is not definitive. For example, I am currently learning React and TypeScript, and I'm always open to learning new skills. For more details, please view my full resume.
                        </p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src={cSharpLogo} alt="Image" />
                                <h5>C#</h5>
                            </div>
                            <div className="item">
                                <img src={dotNetLogo} alt="Image" />
                                <h5>.NET</h5>
                            </div>
                            <div className="item">
                                <img src={azureLogo} alt="Image" />
                                <h5>Microsoft Azure</h5>
                            </div>
                            <div className="item">
                                <img src={sqlLogo} alt="Image" />
                                <h5>SQL</h5>
                            </div>
                            <div className="item">
                                <img src={javaLogo} alt="Image" />
                                <h5>Java</h5>
                            </div>
                            <div className="item">
                                <img src={springBootLogo} alt="Image" />
                                <h5>Spring Boot</h5>
                            </div>
                            <div className="item">
                                <img src={awsLogo} alt="Image" />
                                <h5>Amazon Web Services</h5>
                            </div>
                            <div className="item">
                                <img src={pythonLogo} alt="Image" />
                                <h5>Python</h5>
                            </div>
                            <div className="item">
                                <img src={gitLogo} alt="Image" />
                                <h5>Git</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
